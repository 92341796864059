<template>
  <div class="table-container">
    <table>
      <thead>
        <tr class="cell">
          <th :rowspan="selectedValuesOptions.length > 1 ? 2 : 1">
            <span class="table-logo">
              <feather-icon size="15" icon="CalendarIcon" /><span>{{
                startAndEndDateText
              }}</span></span
            >
          </th>
          <th :colspan="selectedValuesOptions.length"></th>
          <th
            v-for="(column, index) in tableData.columns"
            :key="`${index}:th`"
            :colspan="selectedValuesOptions.length"
          >
            {{ column.label }}
          </th>
        </tr>
        <template v-if="selectedValuesOptions.length > 1">
          <tr class="cell">
            <th
              v-for="(rowItem, rowItemIndex) in selectedValuesOptions"
              :key="`:${rowItemIndex}:${rowItem.label}`"
              :class="{
                'col-seperator':
                  rowItemIndex + 1 == selectedValuesOptions.length,
              }"
              class="total-th sortable-th"
              @click="toggleSortOption(rowItem.key)"
            >
              <div>
                <small>{{ rowItem.label }}</small>
                <template v-if="sortColumnKey == rowItem.key">
                  <feather-icon
                    v-if="sortDirection == 'DESC'"
                    icon="ArrowDownIcon"
                  />
                  <feather-icon v-else icon="ArrowUpIcon" />
                </template>
              </div>
            </th>
            <template v-for="(column, index) in tableData.columns">
              <th
                v-for="(rowItem, rowItemIndex) in selectedValuesOptions"
                :key="`${index}:${rowItemIndex}:${rowItem.label}`"
                :class="{
                  'col-seperator':
                    rowItemIndex + 1 == selectedValuesOptions.length,
                }"
              >
                <small>{{ rowItem.label }}</small>
              </th>
            </template>
          </tr>
        </template>
        <tr class="total-tr">
          <th></th>
          <th
            v-if="selectedValuesOptions.length == 1"
            class="sortable-th"
            @click="toggleSortOption(selectedValuesOptions[0].key)"
          >
            <small>Total</small>
            <template v-if="sortColumnKey == selectedValuesOptions[0].key">
              <feather-icon
                v-if="sortDirection == 'DESC'"
                icon="ArrowDownIcon"
              />
              <feather-icon v-else icon="ArrowUpIcon" />
            </template>
          </th>
          <th v-else :colspan="selectedValuesOptions.length">Total</th>
          <th v-for="(rowCol, index) in totalRowColumns" :key="index">
            {{ rowCol.displayValue }}
          </th>
        </tr>
      </thead>
      <tbody>
        <data-table-row-level
          :data="tableData.data"
          :columns="tableData.columns"
          :level="0"
          :selectedValuesOptions="selectedValuesOptions"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import DataTableRowLevel from "./DataTableRowLevel.vue";
import DataTableRow from "./DataTableRow.vue";
import moment from "moment";
export default {
  components: { DataTableRowLevel, DataTableRow },
  props: ["tableData", "selectedValuesOptions", "dateRange", "sortOption"],
  data() {
    return {
      localSortOption: { columnKey: null, direction: null },
    };
  },
  created() {
    if (this.sortOption != null) this.localSortOption = this.sortOption;
  },
  computed: {
    sortColumnKey() {
      return this.localSortOption.columnKey;
    },
    sortDirection() {
      return this.localSortOption.direction;
    },
    startAndEndDateText() {
      const startDate = moment(this.dateRange.startDate);
      const endDate = moment(this.dateRange.endDate);
      return `${startDate.format("DD MMM YYYY")} > ${endDate.format(
        "DD MMM YYYY"
      )}`;
    },
    totalRowColumns() {
      let result = [];
      this.tableData.columns.forEach((col) => {
        this.selectedValuesOptions.forEach((option, index) => {
          let value = this.tableData.data.reduce(
            (prev, curr) =>
              (prev += curr.rowColumnsData.find((i) => i.key == col.value)
                .values[index].value),
            0
          );
          result.push({
            value: value,
            displayValue: option.formatter
              ? option.formatter(value)
              : this.$options.filters.toCurrency(value),
          });
        });
      });
      return result;
    },
  },
  methods: {
    toggleSortOption(columnKey) {
      if (this.sortColumnKey != columnKey) {
        this.localSortOption = { columnKey: columnKey, direction: "DESC" };
      } else {
        if (this.sortDirection == null) this.localSortOption.direction = "DESC";
        else if (this.sortDirection == "DESC")
          this.localSortOption.direction = "ASC";
        else this.localSortOption = { columnKey: null, direction: null };
      }
      this.$emit(
        "sort-option-changed",
        this.localSortOption.columnKey != null ? this.localSortOption : null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sortable-th {
  &:hover {
    cursor: pointer;
    color: #000;
  }
  div {
    display: flex;
    align-items: center;
    small {
      font-weight: bold;
    }
    svg {
      margin-left: 5px;
    }
  }
}

.table-logo {
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }
}
.table-container {
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100%;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
}

thead {
  background-color: #f3f2f7;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;

  tr {
    th {
      padding: 0px 5px;
      background-color: #f3f2f7;
      text-align: center;
      border-bottom: 2px solid #e4e4e4;

      &:not(:last-child) {
        border-right: 2px solid #e4e4e4;
      }

      &:nth-child(2) {
        padding-left: 10px;
      }

      &.total-th {
        background-color: #e9e9ec;
        font-size: 12px;
        font-weight: bold;
      }
    }
    &:first-child {
      th {
        &:first-child {
          color: #407e3d;
          z-index: 9999;
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          padding: 5px 0px;
          border-bottom: 2px solid #f3f2f7;
          width: 200px;
        }
      }
    }
    &:last-child {
      th {
        border-bottom: 2px solid #e4e4e4;
      }
    }
  }
  tr.total-tr {
    &:not(:first-child) {
      background-color: #f3f2f7;
    }

    th {
      background-color: #e9e9ec;
      font-size: 12px;
      font-weight: bold;
      padding: 0 10px;
      text-align: right;
      &:first-child {
        background: #f3f2f7;
        position: sticky;
        left: 0;
        box-shadow: 4px 0 4px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>