<template>
  <b-modal
    id="booststrapmodal"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    size="lg"
  >
    <div>
      <b-table :fields="tableFields" :items="tableValues">
        <template #cell(voucherId)="data">
          <a href="#">#{{ data.value }}</a>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BTable } from "bootstrap-vue";
export default {
  components: { BModal, BTable },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    tableFields() {
      return [
        {
          key: "voucherId",
          label: "Faktura nr.",
          sortable: true,
        },
        {
          key: "voucherDate",
          label: "Dato",
          sortable: true,
        },
        {
          key: "supplierName",
          label: "Leverandør",
          sortable: true,
        },
        {
          key: "customerName",
          label: "Kunde",
          sortable: true,
        },
        {
          key: "value",
          label: "Værdi",
          sortable: true,
        },
      ];
    },
    tableValues() {
      return [
        {
          voucherId: "12344",
          voucherDate: "31/01/2023",
          supplierName: "Leverandør A",
          customerName: "Location A",
          value: "10,000",
        },
        {
          voucherId: "55444",
          voucherDate: "20/01/2023",
          supplierName: "Leverandør A",
          customerName: "Location A",
          value: "21,000",
        },
      ];
    },
  },
  methods: {
    handleModalHidden() {
      this.visible = false;
    },
    open() {
      this.visible = true;
    },
  },
};
</script>