 <template>
  <div id="data-insights-con">
    <template v-if="isLoading">
      <div class="page-skeleton">
        <div class="skeleton-container">
          <div class="skeleton-box large">
            <div class="skeleton-text">Loading...</div>
          </div>
          <div class="skeleton-box small"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="left-area">
        <b-card>
          <div class="info-text-con" v-if="!canShowTableResults">
            <h3 class="text-muted">
              {{
                T(
                  "Web.DataInsightsPage.ChooseRowsAndValuesToDisplay",
                  "Choose rows and values to display"
                )
              }}
            </h3>
          </div>
          <template v-else>
            <b-overlay
              v-if="tableIsBuilding"
              :show="true"
              style="margin-top: 30px"
            >
              <div style="height: 100%"></div>
            </b-overlay>
            <template v-else>
              <data-insight-table
                :table-data="tableData"
                :selected-values-options="selectedValuesOptions"
                :date-range="localUserSettings.dateRange"
                @sort-option-changed="
                  (option) => (localUserSettings.sortOption = option)
                "
                :sort-option="sortOption"
              />
            </template>
          </template>
        </b-card>
      </div>
      <div class="right-area">
        <b-card no-body>
          <div class="data-insights-brand-con">
            <span class="table-logo"
              >Data Insights <feather-icon size="20" icon="DatabaseIcon"
            /></span>
          </div>
          <div class="content-inner">
            <collapsable-menu-item
              :menuTitle="T('Web.DataInsightsPage.Periode', 'Periode')"
              icon="CalendarIcon"
            >
              <date-range-picker
                ref="picker"
                v-model="localUserSettings.dateRange"
                opens="left"
                :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
                :append-to-body="true"
                @update="handleDateRangeChange"
              >
              </date-range-picker>
            </collapsable-menu-item>
            <collapsable-menu-item
              :menuTitle="T('Web.DataInsightsPage.Columns', 'Columns')"
              icon="ArrowDownIcon"
            >
              <b-form-radio
                v-for="option in visibleColumnOptions"
                :key="option.key"
                v-model="localUserSettings.selectedColumnOption"
                name="columnOption"
                :value="option.key"
                @change="refreshTable"
                >{{ option.label }}</b-form-radio
              >
              <div class="editOptionsBtn">
                <a
                  href="#"
                  @click.prevent="openColumnOptionsVisibilityEditModal"
                >
                  {{ T("Web.DataInsightsPage.EditOptions", "Edit options") }}
                </a>
              </div>
            </collapsable-menu-item>
            <collapsable-menu-item
              :menuTitle="T('Web.DataInsightsPage.Rows', 'Rows')"
              :default-uncollapsed="true"
              icon="ArrowRightIcon"
            >
              <b-form-checkbox
                v-for="option in visibleRowOptions"
                :key="option.key"
                v-model="localUserSettings.selectedRowOptions"
                name="rowOption"
                :value="option.key"
                @change="refreshTable"
                >{{ option.label }}</b-form-checkbox
              >
              <div class="editOptionsBtn">
                <a href="#" @click.prevent="openRowOptionsVisibilityEditModal">
                  {{ T("Web.DataInsightsPage.EditOptions", "Edit options") }}
                </a>
              </div>
              <div
                class="order-editor-con"
                v-if="selectedRowOptions.length > 1"
              >
                <label>{{ T("Web.DataInsightsPage.Order", "Order") }}</label>
                <div class="order-editor">
                  <draggable
                    v-model="localUserSettings.selectedRowOptions"
                    group="people"
                    @change="refreshTable"
                  >
                    <div v-for="option in selectedRowOptions" :key="option.key">
                      {{ option.label }}
                      <div class="icon-controls">
                        <feather-icon icon="MoreVerticalIcon" />
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </collapsable-menu-item>
            <collapsable-menu-item
              :menuTitle="T('Web.DataInsightsPage.Values', 'Values')"
              :default-uncollapsed="true"
              icon="DatabaseIcon"
            >
              <b-form-checkbox
                v-for="option in visibleValuesOptions"
                :key="option.key"
                v-model="localUserSettings.selectedValuesOptions"
                name="valuesOptions"
                :value="option.key"
                @change="refreshTable"
                >{{ option.label }}</b-form-checkbox
              >
              <div class="editOptionsBtn">
                <a
                  href="#"
                  @click.prevent="openValueOptionsVisibilityEditModal"
                >
                  {{ T("Web.DataInsightsPage.EditOptions", "Edit options") }}
                </a>
              </div>
              <div
                class="order-editor-con"
                v-if="selectedValuesOptions.length > 1"
              >
                <label>{{ T("Web.DataInsightsPage.Order", "Order") }}</label>
                <div class="order-editor">
                  <draggable
                    v-model="localUserSettings.selectedValuesOptions"
                    group="people"
                    @change="refreshTable"
                  >
                    <div
                      v-for="option in selectedValuesOptions"
                      :key="option.key"
                    >
                      {{ option.label }}
                      <div class="icon-controls">
                        <feather-icon icon="MoreVerticalIcon" />
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </collapsable-menu-item>
            <collapsable-menu-item menuTitle="Filterering" icon="FilterIcon">
              <div
                class="filter-editor-con"
                v-for="option in visibleFilterOptions"
                :key="option.key"
              >
                <div class="filter-head">
                  <label>{{ option.label }}</label>
                  <a
                    href="#"
                    @click.prevent="selectDeselectAllFilterOption(option.key)"
                    >{{ T("Web.Generic.SelectDeselectAll") }}</a
                  >
                </div>
                <div class="filter-editor">
                  <div
                    v-for="filterOption in option.value"
                    :key="filterOption.key"
                  >
                    <b-form-checkbox
                      v-model="localUserSettings.filters[option.key]"
                      :value="filterOption.id"
                      @change="refreshTable"
                      >{{ filterOption.name }}</b-form-checkbox
                    >
                  </div>
                </div>
              </div>
            </collapsable-menu-item>
          </div>

          <edit-visible-options-modal
            ref="columnOptionsVisibilityEditModal"
            :options="columnOptions"
            v-model="localUserSettings.visibleOptions.columnOptions"
            :disabled-options="localUserSettings.selectedColumnOption"
          />
          <edit-visible-options-modal
            ref="valueOptionsVisibilityEditModal"
            :options="valuesOptions"
            v-model="localUserSettings.visibleOptions.valuesOptions"
            :disabled-options="localUserSettings.selectedValuesOptions"
          />
          <edit-visible-options-modal
            ref="rowOptionsVisibilityEditModal"
            :options="rowOptions"
            :disabled-options="localUserSettings.selectedRowOptions"
            v-model="localUserSettings.visibleOptions.rowOptions"
          />
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ResourceService from "@/services/base/resource.service";
import {
  BButton,
  BCard,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BOverlay,
} from "bootstrap-vue";
import moment from "moment";
import { uuid } from "vue-uuid";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import draggable from "vuedraggable";
import CollapsableMenuItem from "./components/CollapsableMenuItem.vue";
import DataInsightTable from "./components/DataInsightTable.vue";
import EditVisibleOptionsModal from "./components/EditVisibleOptionsModal.vue";
export default {
  components: {
    BCard,
    BFormRadio,
    BFormGroup,
    BFormCheckbox,
    DataInsightTable,
    CollapsableMenuItem,
    DateRangePicker,
    draggable,
    BFormInput,
    BButton,
    EditVisibleOptionsModal,
    PageLoader,
    BOverlay,
  },
  data() {
    return {
      tableIsBuilding: false,
      localUserSettings: null,
      isLoading: true,
      rawDataContext: [],
      tableDataSource: [],
      tableIsRendering: false,
      /** <--- Background data ---> */
      locations: [],
      fractions: [],
      suppliers: [],
      infocards: [],
      ewcs: [],
      ghgCodes: [],
      containerTypes: [],
      containerTypeAndSizes: [],
      receivingFacilities: [],
      wasteCategoryOptions: [
        {
          id: "Unknown",
          name: this.T(`Web.Generic.WasteCategories.Unknown`),
        },
        {
          id: "Rezycl",
          name: this.T(`Web.Generic.WasteCategories.Rezycl`),
        },
        {
          id: "Combostion",
          name: this.T(`Web.Generic.WasteCategories.Combostion`),
        },
        {
          id: "Special",
          name: this.T(`Web.Generic.WasteCategories.Special`),
        },
        {
          id: "Landfill",
          name: this.T(`Web.Generic.WasteCategories.Landfill`),
        },
        {
          id: "Reuse",
          name: this.T(`Web.Generic.WasteCategories.Reuse`),
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    const containerElm = document.getElementById("data-insights-con");
    const elementRect = containerElm.getBoundingClientRect();
    const elementTop = elementRect.top + window.scrollY;
    containerElm.style.height = `calc(100vh - ${elementTop}px)`;

    // Define services
    const locationsService = new ResourceService("background-data/locations");
    const fractionsService = new ResourceService("waste-fractions");
    const suppliersService = new ResourceService("supplier-contacts");
    const infocardService = new ResourceService("background-data/infocards");
    const ewcServices = new ResourceService("ewcs");
    const ghgCodeService = new ResourceService("background-data/ghg-codes");
    const containerTypeService = new ResourceService(
      "info-cards/containers/types"
    );
    const receivingFacilityService = new ResourceService(
      "receiving-facilities"
    );

    this.localUserSettings = await this.userSettings;

    let infocardIdRowExists = this.localUserSettings.selectedRowOptions.find(
      (o) => o == "infoCardId"
    );
    if (infocardIdRowExists) {
      this.localUserSettings.selectedRowOptions.splice(
        this.localUserSettings.selectedRowOptions.indexOf(infocardIdRowExists),
        1
      );
    }

    // Fetch all data concurrently
    const fetchAllData = async () => {
      const [
        dataInsightData,
        locations,
        fractions,
        suppliers,
        infocards,
        ewcs,
        ghgCodes,
        containerTypes,
        receivingFacilities,
      ] = await Promise.all([
        this.fetchDataPoints(false),
        locationsService.get("").then((res) => res.data.data),
        fractionsService.get("").then((res) => res.data.data),
        suppliersService.get("").then((res) => res.data.data),
        infocardService.get("").then((res) => res.data.data),
        ewcServices.get("").then((res) => res.data.data),
        ghgCodeService.get("").then((res) => res.data.data),
        containerTypeService.get("").then((res) => res.data.data),
        receivingFacilityService.get("").then((res) => res.data.data),
      ]);

      // Transformations
      const ewcFiltered = ewcs
        .filter(
          (e) =>
            e.alias != null || infocards.some((i) => i.ewcCodeName === e.code)
        )
        .map((e) => ({
          id: e.code,
          name: e.code,
          description: e.name,
        }));

      const containerTypeAndSizes = infocards.reduce((prev, curr) => {
        if (
          curr.containerTypeName != null &&
          curr.size > 0 &&
          curr.sizeUnit !== "Unknown"
        ) {
          prev.push({
            id: `${curr.containerTypeId}_${curr.size}_${curr.sizeUnit}`,
            name: `${curr.containerTypeName} ${curr.size} ${
              this.getContainerSizeUnit(curr.sizeUnit).text
            }`,
          });
        }
        return prev;
      }, []);

      // Assign results to instance properties
      this.locations = locations;
      this.fractions = fractions;
      this.suppliers = suppliers;
      this.infocards = infocards;
      this.containerTypes = containerTypes;
      this.receivingFacilities = receivingFacilities;
      this.ewcs = ewcFiltered;
      this.ghgCodes = ghgCodes;
      this.containerTypeAndSizes = containerTypeAndSizes;

      // Build table
      this.tableDataSource = this.buildTableData(
        this.filteredRawDataContext,
        this.sortOption
      );
    };

    // Execute the function
    fetchAllData()
      .catch((error) => {
        console.error("Failed to fetch data:", error);
      })
      .then(() => {
        this.isLoading = false;
      });
  },

  computed: {
    /** <--- Filter & group options ---> */
    columnOptions() {
      return [
        {
          key: "year",
          label: this.T(
            "Web.DataInsightsPage.Options.AnnualDistribution",
            "Annual distribution"
          ),
        },
        {
          key: "month",
          label: this.T(
            "Web.DataInsightsPage.Options.MonthlyDistribution",
            "Monthly distribution"
          ),
        },
        {
          key: "locationId",
          label: this.T("Web.DataInsightsPage.Options.Location", "Location"),
        },
        {
          key: "vocherNumber",
          label: this.T(
            "Web.DataInsightsPage.Options.VocherNumber",
            "Vocher number"
          ),
        },
        {
          key: "infoCardId",
          label: this.T("Web.DataInsightsPage.Options.Infocard", "Infocard"),
        },
        {
          key: "wasteCategory",
          label: this.T(
            "Web.DataInsightsPage.Options.WasteCategory",
            "Waste category"
          ),
        },
        {
          key: "ewcCodeName",
          label: this.T("Web.DataInsightsPage.Options.EwcCode", "EWC code"),
        },
        {
          key: "ghgCodeId",
          label: this.T("Web.DataInsightsPage.Options.GHGCode", "GHG code"),
        },
      ];
    },
    rowOptions() {
      return [
        {
          key: "locationId",
          label: this.T("Web.DataInsightsPage.Options.Location", "Location"),
          value: this.locations,
        },
        {
          key: "wasteFractionId",
          label: this.T(
            "Web.DataInsightsPage.Options.WasteFraction",
            "Fraction"
          ),
          value: [
            ...this.fractions,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutWasteFraction",
                  "Without fraction"
                ),
              },
            ],
          ],
        },
        {
          key: "supplierId",
          label: this.T("Web.DataInsightsPage.Options.Supplier", "Supplier"),
          value: [
            ...this.suppliers,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutSupplier",
                  "Without supplier"
                ),
              },
            ],
          ],
        },
        /*
        {
          key: "infoCardId",
          label: this.T("Web.DataInsightsPage.Options.Infocard", "Infocard"),
          value: this.infocards,
        },
*/
        {
          key: "infocardInternalId",
          label: this.T(
            "Web.DataInsightsPage.Options.InfocardInternalId",
            "Infocard internal group id"
          ),
          value: [
            ...this.infocards.reduce((prev, curr) => {
              if (
                curr.internalGroupId != null &&
                curr.internalGroupId != "" &&
                !prev.some((i) => i.id == curr.internalGroupId)
              ) {
                prev.push({
                  id: curr.internalGroupId,
                  name: curr.internalGroupId,
                });
              }
              return prev;
            }, []),
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutInfocardInternalId",
                  "Without id"
                ),
              },
            ],
          ],
        },
        {
          key: "wasteCategory",
          label: this.T(
            "Web.DataInsightsPage.Options.WasteCategory",
            "Waste category"
          ),
          value: this.wasteCategoryOptions,
        },
        {
          key: "ewcCodeName",
          label: this.T("Web.DataInsightsPage.Options.EwcCode", "EWC code"),
          value: [
            ...this.ewcs,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutEwcCode",
                  "Without EWC code"
                ),
              },
            ],
          ],
        },
        {
          key: "ghgCodeId",
          label: this.T("Web.DataInsightsPage.Options.GHGCode", "GHG code"),
          value: [
            ...this.ghgCodes,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutGHGCode",
                  "Without GHG code"
                ),
              },
            ],
          ],
        },
        {
          key: "containerTypeId",
          label: this.T(
            "Web.DataInsightsPage.Options.ContainerType",
            "Container type"
          ),
          value: [
            ...this.containerTypes,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutContainerType",
                  "No type defined"
                ),
              },
            ],
          ],
        },
        {
          key: "containerTypeAndSize",
          label: this.T(
            "Web.DataInsightsPage.Options.ContainerTypeAndSize",
            "Container type & size"
          ),
          value: [
            ...this.containerTypeAndSizes,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutContainerTypeAndSize",
                  "No type defined"
                ),
              },
            ],
          ],
        },
        {
          key: "receivingFacilityId",
          label: this.T(
            "Web.DataInsightsPage.Options.RecivingFacility",
            "Reciving facility"
          ),
          value: [
            ...this.receivingFacilities,
            ...[
              {
                id: null,
                name: this.T(
                  "Web.DataInsightsPage.Options.WithoutRecivingFacility",
                  "Without reciving facility"
                ),
              },
            ],
          ],
        },
      ];
    },
    valuesOptions() {
      const self = this;
      return [
        {
          key: "tonnageWasteCollected",
          label: this.T(
            "Web.DataInsightsPage.Options.TonnageCollected",
            "Tonnage collected"
          ),
          formatter: function (value) {
            return self.convertTo3decimals(value);
          },
        },
        {
          key: "wasteCollectionCount",
          label: this.T(
            "Web.DataInsightsPage.Options.CollectionCount",
            "Number of collections"
          ),
        },
        {
          key: "wasteCollectionTotalPrice",
          label: this.T(
            "Web.DataInsightsPage.Options.CollectionTotalPrice",
            "Collections total price"
          ),
        },
        {
          key: "wasteCollectionPricePrTonnage",
          label: this.T(
            "Web.DataInsightsPage.Options.AverageCollectionPricePerTon",
            "Average collection price per ton"
          ),
          value_resolver: (items) => {
            let wasteCollectionTotalPrice = items.reduce((prev, curr) => {
              prev += curr.wasteCollectionTotalPrice;
              return prev;
            }, 0);
            let totalTonsCollected = items.reduce((prev, curr) => {
              prev += curr.tonnageWasteCollected;
              return prev;
            }, 0);
            return totalTonsCollected <= 0
              ? 0
              : wasteCollectionTotalPrice / totalTonsCollected;
          },
        },
        {
          key: "wasteCollectionAvgUnitPrice",
          label: this.T(
            "Web.DataInsightsPage.Options.AverageCollectionUnitPrice",
            "Average collection unit price"
          ),
          value_resolver: (items) => {
            let totalUnitPrice = items.reduce((prev, curr) => {
              prev += curr.wasteCollectionUnitPrice;
              return prev;
            }, 0);
            let unitPricesAmount = items.reduce((prev, curr) => {
              prev += curr.wasteCollectionUnitPrice != null ? 1 : 0;
              return prev;
            }, 0);
            return unitPricesAmount > 0 ? totalUnitPrice / unitPricesAmount : 0;
          },
        },
        {
          key: "wasteCollectionPricePrCollection",
          label: this.T(
            "Web.DataInsightsPage.Options.AveragePricePrCollection",
            "Average price per collection"
          ),
          value_resolver: (items) => {
            let wasteCollectionTotalPrice = items.reduce((prev, curr) => {
              prev += curr.wasteCollectionTotalPrice;
              return prev;
            }, 0);
            let totalCollections = items.reduce((prev, curr) => {
              prev += curr.wasteCollectionCount;
              return prev;
            }, 0);
            return totalCollections <= 0
              ? 0
              : wasteCollectionTotalPrice / totalCollections;
          },
        },
        {
          key: "incomeTotalPrice",
          label: this.T(
            "Web.DataInsightsPage.Options.GrossIncome",
            "Gross income"
          ),
        },
        {
          key: "incomeTotalNettoPrice",
          label: this.T("Web.DataInsightsPage.Options.NetIncome", "Net income"),
          value_resolver: (items) => {
            let incomeTotal = items.reduce((prev, curr) => {
              prev += curr.incomeTotalPrice;
              return prev;
            }, 0);
            let chargeTotal = items.reduce((prev, curr) => {
              prev += curr.chageTotalPrice;
              return prev;
            }, 0);

            return incomeTotal - chargeTotal;
          },
        },
        {
          key: "incomeAvgUnitPrice",
          label: this.T(
            "Web.DataInsightsPage.Options.IncomeAverageUnitPrice",
            "Average unit price of income"
          ),
          value_resolver: (items) => {
            let totalUnitPrice = items.reduce((prev, curr) => {
              prev += curr.incomeUnitPrice;
              return prev;
            }, 0);
            let unitPricesAmount = items.reduce((prev, curr) => {
              prev += curr.incomeUnitPrice != null ? 1 : 0;
              return prev;
            }, 0);
            return unitPricesAmount > 0 ? totalUnitPrice / unitPricesAmount : 0;
          },
        },
        {
          key: "incomeAvgPricePrTonnage",
          label: this.T(
            "Web.DataInsightsPage.Options.AverageIncomePerTon",
            "Average income per ton"
          ),
          value_resolver: (items) => {
            let incomeTotal = items.reduce((prev, curr) => {
              prev += curr.incomeTotalPrice;
              return prev;
            }, 0);
            let totalTonsCollected = items.reduce((prev, curr) => {
              prev += curr.tonnageWasteCollected;
              return prev;
            }, 0);
            return totalTonsCollected <= 0
              ? 0
              : incomeTotal / totalTonsCollected;
          },
        },
        {
          key: "chageTotalPrice",
          label: this.T(
            "Web.DataInsightsPage.Options.GrossCharge",
            "Gross charge"
          ),
        },
        {
          key: "chageTotalNettoPrice",
          label: this.T("Web.DataInsightsPage.Options.NetCharge", "Net charge"),
          value_resolver: (items) => {
            let incomeTotal = items.reduce((prev, curr) => {
              prev += curr.incomeTotalPrice;
              return prev;
            }, 0);
            let chargeTotal = items.reduce((prev, curr) => {
              prev += curr.chageTotalPrice;
              return prev;
            }, 0);

            return chargeTotal - incomeTotal;
          },
        },
        {
          key: "chageAvgUnitPrice",
          label: this.T(
            "Web.DataInsightsPage.Options.ChargeAverageUnitPrice",
            "Charge average unit price"
          ),
          value_resolver: (items) => {
            let totalUnitPrice = items.reduce((prev, curr) => {
              prev += curr.chageUnitPrice;
              return prev;
            }, 0);
            let unitPricesAmount = items.reduce((prev, curr) => {
              prev += curr.chageUnitPrice != null ? 1 : 0;
              return prev;
            }, 0);
            return unitPricesAmount > 0 ? totalUnitPrice / unitPricesAmount : 0;
          },
        },
        {
          key: "chargeAvgPricePrTonnage",
          label: this.T(
            "Web.DataInsightsPage.Options.ChargeAveragePerTon",
            "Charge average per ton"
          ),
          value_resolver: (items) => {
            let incomeTotal = items.reduce((prev, curr) => {
              prev += curr.chageTotalPrice;
              return prev;
            }, 0);
            let totalTonsCollected = items.reduce((prev, curr) => {
              prev += curr.tonnageWasteCollected;
              return prev;
            }, 0);
            return totalTonsCollected <= 0
              ? 0
              : incomeTotal / totalTonsCollected;
          },
        },
        {
          key: "abolitionCO2",
          label: this.T(
            "Web.DataInsightsPage.Options.CO2EmissionsFromDisposal",
            "CO2 emissions from disposal"
          ),
        },
        {
          key: "transportCO2",
          label: this.T(
            "Web.DataInsightsPage.Options.CO2EmissionsFromTransportation",
            "CO2 emissions from transportation"
          ),
        },
        {
          key: "kmTransported",
          label: this.T(
            "Web.DataInsightsPage.Options.KilometersTransported",
            "Kilometers transported"
          ),
        },
      ];
    },
    visibleColumnOptions() {
      let visibleOptions = this.localUserSettings.visibleOptions;
      return this.columnOptions.filter((o) =>
        visibleOptions.columnOptions.includes(o.key)
      );
    },
    visibleRowOptions() {
      let visibleOptions = this.localUserSettings.visibleOptions;
      return this.rowOptions.filter((o) =>
        visibleOptions.rowOptions.includes(o.key)
      );
    },
    visibleValuesOptions() {
      let visibleOptions = this.localUserSettings.visibleOptions;
      return this.valuesOptions.filter((o) =>
        visibleOptions.valuesOptions.includes(o.key)
      );
    },
    visibleFilterOptions() {
      return this.rowOptions.filter(
        (o) =>
          this.selectedRowOptions.some((r) => r.key == o.key) ||
          this.selectedColumnOption == o.key
      );
    },
    selectedColumnOption() {
      return this.localUserSettings.selectedColumnOption;
    },

    /** <--- Column, rows & data ---> */
    columns() {
      if (this.localUserSettings.selectedColumnOption == null) return [];
      switch (this.localUserSettings.selectedColumnOption) {
        case "year":
          return this.getYearsInPeriod(
            new Date(this.localUserSettings.dateRange.startDate),
            new Date(this.localUserSettings.dateRange.endDate)
          ).map((year) => {
            return { label: year, value: year };
          });

        case "month":
          return this.getMonthsInPeriod(
            new Date(this.localUserSettings.dateRange.startDate),
            new Date(this.localUserSettings.dateRange.endDate)
          ).map((month) => {
            return {
              label: `${month.monthName} ${month.year}`,
              value: `${month.month}-${month.year}`,
            };
          });
        case "locationId":
          return this.locations
            .filter((i) => this.filters.locationId.includes(i.id))
            .map((location) => {
              return {
                label: location.name,
                value: location.id,
              };
            });
        case "vocherNumber":
          return this.filteredRawDataContext.reduce((prev, curr) => {
            if (!prev.some((i) => i.label == curr.vocherNumber))
              prev.push({ label: curr.vocherNumber, value: curr.vocherNumber });
            return prev;
          }, []);
        case "infoCardId":
          return this.infocards.map((infocard) => {
            return {
              label: infocard.name,
              value: infocard.id,
            };
          });
        case "wasteCategory":
          return this.wasteCategoryOptions.map((option) => {
            return {
              label: option.name,
              value: option.id,
            };
          });
        case "ewcCodeName":
          return [
            ...this.ewcs.map((option) => {
              return {
                label: option.name,
                value: option.id,
              };
            }),
            ...[
              {
                label: this.T("Web.DataInsightsPage.Options.WithoutEwcCode"),
                value: null,
              },
            ],
          ];
        case "ghgCodeId":
          return [
            ...this.ghgCodes.map((option) => {
              return {
                label: option.name,
                value: option.id,
              };
            }),
            ...[
              {
                label: this.T("Web.DataInsightsPage.Options.WithoutGHGCode"),
                value: null,
              },
            ],
          ];
      }
    },
    canShowTableResults() {
      return (
        this.localUserSettings != null &&
        this.selectedRowOptions.length > 0 &&
        this.selectedValuesOptions.length > 0
      );
    },
    tableData() {
      this.tableIsBuilding = true;
      let data = {
        columns: this.columns,
        data: this.tableDataSource, //this.buildTableData(this.filteredRawDataContext, this.sortOption),
      };
      this.tableIsBuilding = false;
      return data;
    },
    filteredRawDataContext() {
      if (this.rawDataContext.length == 0) return [];
      let filteredResult = this.rawDataContext;
      this.visibleFilterOptions.forEach((filterOption) => {
        let filtersPropertyValues = this.rowOptions
          .find((o) => o.key == filterOption.key)
          .value.map((i) => i.id);
        filteredResult = filteredResult.filter((i) => {
          return filtersPropertyValues.includes(i[filterOption.key]);
        });
      });
      return filteredResult;
    },
    tableRows() {
      return this.getTableRowsForLevel();
    },
    /** Pivo settings */
    userSettings: {
      async get() {
        return await this.getUserSetting(
          "data.insights.pivo.settings",
          this.defaultUserSettings
        );
      },
      async set(value) {
        await this.setUserSettingAsync("data.insights.pivo.settings", value);
      },
    },
    defaultUserSettings() {
      let filters = {};
      this.rowOptions.forEach((option) => {
        filters[option.key] = option.value.map((i) => i.id);
      });
      return {
        dateRange: {
          startDate: moment(new Date()).subtract("year", 1),
          endDate: moment(new Date()),
        },
        selectedColumnOption: "month",
        selectedRowOptions: [],
        selectedValuesOptions: [],
        filters: filters,
        visibleOptions: {
          valuesOptions: [
            "tonnageWasteCollected",
            "wasteCollectionCount",
            "wasteCollectionTotalPrice",
            "incomeTotalPrice",
            "chageTotalPrice",
          ],
          columnOptions: ["year", "month"],
          rowOptions: [
            "locationId",
            "wasteFractionId",
            "supplierId",
            "infoCardId",
            "wasteCategory",
          ],
        },
        sortOption: null,
      };
    },

    filters: {
      get() {
        return this.localUserSettings.filters;
      },
      set(value) {
        this.localUserSettings.filters = value;
      },
    },
    selectedValuesOptions() {
      return this.localUserSettings.selectedValuesOptions.map((optionKey) =>
        this.valuesOptions.find((o) => o.key == optionKey)
      );
    },
    selectedRowOptions() {
      return this.localUserSettings.selectedRowOptions.map((optionKey) =>
        this.rowOptions.find((o) => o.key == optionKey)
      );
    },
    sortOption() {
      return this.localUserSettings.sortOption;
    },
  },
  methods: {
    async handleDateRangeChange() {
      this.tableIsBuilding = true;
      await this.fetchDataPoints();
      this.refreshTable();
    },

    refreshTable() {
      this.tableIsBuilding = true;
      this.tableIsRendering = true;
      setTimeout(() => {
        this.tableDataSource = this.buildTableData(
          this.filteredRawDataContext,
          this.sortOption
        );
        this.tableIsBuilding = false;
      }, 0);
    },
    buildTableData(dataContext, sortOption, optionIndex = 0) {
      if (this.selectedRowOptions.length === 0) return [];

      const result = [];
      const selectedRowOption = this.selectedRowOptions[optionIndex];
      const selectedColumnOption = this.columnOptions.find(
        (o) => o.key === this.localUserSettings.selectedColumnOption
      );

      // Precompute filters for row options
      const filteredRowValues = selectedRowOption.value.filter((i) =>
        this.filters[selectedRowOption.key].includes(i.id)
      );

      filteredRowValues.forEach((elm) => {
        // Filter data once for the current row option
        const filteredDataContext = dataContext.filter(
          (c) => c[selectedRowOption.key] === elm.id
        );

        // Precompute column data
        const rowColumnsData = this.columns.map((column) => {
          const columnKey = column.value;
          return {
            key: columnKey,
            values: this.selectedValuesOptions.map((option) => {
              const resolver = option.value_resolver;
              const formatter =
                option.formatter || this.$options.filters.toCurrency;

              // Filter once per column
              const valueItems = filteredDataContext.filter(
                (d) => d[selectedColumnOption.key] === columnKey
              );

              const value = resolver
                ? resolver(valueItems)
                : valueItems.reduce((prev, curr) => prev + curr[option.key], 0);

              const displayValue =
                valueItems.length === 0 || isNaN(value)
                  ? "-"
                  : formatter(value);

              return {
                label: option.label,
                value,
                displayValue,
              };
            }),
          };
        });

        // Compute row total data
        const rowTotalValues = this.selectedValuesOptions.map((option) => {
          const totalValue = rowColumnsData.reduce((prev, curr) => {
            const rowValueToAdd = curr.values.find(
              (o) => o.label === option.label
            ).value;
            return prev + rowValueToAdd;
          }, 0);

          return {
            key: option.key,
            label: option.label,
            value: totalValue,
            displayValue: option.formatter
              ? option.formatter(totalValue)
              : this.$options.filters.toCurrency(totalValue),
          };
        });

        // Construct result item
        const resultItem = {
          rowId: uuid.v4(),
          label: elm.name,
          id: elm.id,
          children: [],
          rowData: filteredDataContext,
          rowColumnsData,
          rowTotals: rowTotalValues,
        };

        // Recursively build child data if necessary
        if (this.selectedRowOptions.length > optionIndex + 1) {
          resultItem.children = this.buildTableData(
            filteredDataContext,
            sortOption,
            optionIndex + 1
          );
        }

        result.push(resultItem);
      });

      // Sort results
      if (sortOption) {
        result.sort((a, b) => {
          const valueA =
            a.rowTotals.find((r) => r.key === sortOption.columnKey)?.value || 0;
          const valueB =
            b.rowTotals.find((r) => r.key === sortOption.columnKey)?.value || 0;
          return sortOption.direction === "DESC"
            ? valueB - valueA
            : valueA - valueB;
        });
      } else {
        result.sort((a, b) => a.label.localeCompare(b.label));
      }

      return result;
    },

    buildTableData1(dataContext, sortOption, optionIndex = 0) {
      if (this.selectedRowOptions.length == 0) return [];
      let result = [];
      let selectedRowOption = this.selectedRowOptions[optionIndex];
      let selectedColumnOption = this.columnOptions.find(
        (o) => o.key == this.localUserSettings.selectedColumnOption
      );
      selectedRowOption.value
        .filter((i) => this.filters[selectedRowOption.key].includes(i.id))
        .forEach((elm) => {
          let filteredDataContext = dataContext.filter((c) => {
            return c[selectedRowOption.key] == elm.id;
          });
          /** Row data */
          let rowColumnsData = this.columns.map((column) => {
            return {
              key: column.value,
              values: this.selectedValuesOptions.map((option) => {
                let valueItems = filteredDataContext.filter(
                  (d) => d[selectedColumnOption.key] == column.value
                );
                let value = 0;
                if (option.value_resolver != undefined) {
                  value = option.value_resolver(valueItems);
                } else {
                  value = valueItems.reduce(
                    (prev, curr) => (prev += curr[option.key]),
                    0
                  );
                }
                let displayValue = option.formatter
                  ? option.formatter(value)
                  : this.$options.filters.toCurrency(value);
                // No data present
                if (valueItems.length == 0) {
                }
                return {
                  label: option.label,
                  value: value,
                  displayValue:
                    valueItems.length == 0 || displayValue == "NaN"
                      ? "-"
                      : displayValue,
                };
              }),
            };
          });
          /** Row total data */
          let rowTotalValues = this.selectedValuesOptions.map((option) => {
            let totalValue = rowColumnsData.reduce((prev, curr) => {
              let rowValueToAdd = curr.values.find(
                (o) => o.label == option.label
              ).value;
              return (prev += rowValueToAdd);
            }, 0);
            return {
              key: option.key,
              label: option.label,
              value: totalValue,
              displayValue: option.formatter
                ? option.formatter(totalValue)
                : this.$options.filters.toCurrency(totalValue),
            };
          });

          let resultItem = {
            rowId: uuid.v4(),
            label: elm.name,
            id: elm.id,
            children: [],
            rowData: filteredDataContext,
            rowColumnsData: rowColumnsData,
            rowTotals: rowTotalValues,
          };

          if (this.selectedRowOptions.length > optionIndex + 1) {
            resultItem.children = this.buildTableData(
              filteredDataContext,
              sortOption,
              optionIndex + 1
            );
          }

          result.push(resultItem);
        });
      if (this.sortOption != null) {
        result = result.sort((a, b) => {
          let valueA = a.rowTotals.find(
            (r) => r.key == sortOption.columnKey
          )?.value;
          let valueB = b.rowTotals.find(
            (r) => r.key == sortOption.columnKey
          )?.value;
          return sortOption.direction == "DESC"
            ? valueA - valueB
            : valueB - valueA;
        });
      } else {
        result = result.sort((a, b) => a.label?.localeCompare(b.label));
      }
      return result;
    },
    getYearsInPeriod(startDate, endDate) {
      let years = [];
      let currentDate = moment(startDate).clone();
      while (currentDate.isSameOrBefore(moment(endDate), "year")) {
        years.push(currentDate.year());
        currentDate.add(1, "year");
      }
      return years;
    },
    getMonthsInPeriod(startDate, endDate) {
      let months = [];
      let currentDate = moment(startDate).clone();

      while (currentDate.isSameOrBefore(moment(endDate), "month")) {
        months.push({
          monthName: currentDate.format("MMM"),
          month: currentDate.format("MM"),
          year: currentDate.format("YYYY"),
        });
        currentDate.add(1, "month");
      }
      return months;
    },
    getTableRowsForLevel(level = 0) {
      if (this.selectedRowOptions.length == 0) return [];
      let result = [];
      this.selectedRowOptions[level].value.forEach((element) => {
        result.push({
          level: level,
          element: element,
        });
        if (this.selectedRowOptions.length > level + 1) {
          result = result.concat(this.getTableRowsForLevel(level + 1));
        }
      });
      return result;
    },
    selectDeselectAllFilterOption(optionKey) {
      if (
        this.filters[optionKey].length ==
        this.rowOptions.find((o) => o.key == optionKey).value.length
      ) {
        this.filters[optionKey] = [];
      } else {
        this.filters[optionKey] = this.rowOptions
          .find((o) => o.key == optionKey)
          .value.map((v) => v.id);
      }
      this.refreshTable();
    },
    openValueOptionsVisibilityEditModal() {
      this.$refs.valueOptionsVisibilityEditModal.open();
    },
    openColumnOptionsVisibilityEditModal() {
      this.$refs.columnOptionsVisibilityEditModal.open();
    },
    openRowOptionsVisibilityEditModal() {
      this.$refs.rowOptionsVisibilityEditModal.open();
    },
    async fetchDataPoints(setLoading = true) {
      try {
        const service = new ResourceService("data-insights");
        const { data } = await service.get("data-points", {
          fromDate: moment(this.localUserSettings.dateRange.startDate).format(
            "yyyy-MM-DD"
          ),
          toDate: moment(this.localUserSettings.dateRange.endDate).format(
            "yyyy-MM-DD"
          ),
        });
        this.rawDataContext = data.data;
        if (setLoading) {
          this.isLoading = true;
        }
      } finally {
        if (setLoading) {
          this.isLoading = false;
        }
      }
    },
  },
  watch: {
    localUserSettings: {
      handler(newValue, oldValue) {
        if (oldValue == null) return;
        this.userSettings = newValue;
      },
      deep: true,
    },
  },
};
</script>
<style  >
.sortable-ghost,
.sortable-chosen {
  background: #e3ebe1;
}
</style>
<style lang="scss" scoped>
#data-insights-con {
  display: flex;
  height: 100vh;
  padding: 0 0 20px 0;
  .left-area {
    width: 80%;
    margin-right: 10px;
    height: 100%;

    .card {
      height: 100%;
      .info-text-con {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .right-area {
    width: 20%;
    height: 100%;
    .card {
      height: 100%;
      padding: 0px 0px 20px 0px;
      display: flex;
      align-items: stretch;

      .content-inner {
        height: 100%;
        overflow-y: auto;

        .editOptionsBtn {
          font-weight: 500;
          font-size: 12px;
          margin: 5px 0px;
          svg {
            margin-right: 10px;
          }
        }
      }

      .data-insights-brand-con {
        background-color: #e3ebe1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #407e3d;
        font-size: 20px;
        font-weight: 300;
        padding: 10px 0px;
        position: sticky;
        top: 0;
        border-bottom: 2px solid #407e3d;
      }
      .content-inner {
        padding: 1.5rem;
      }
    }
  }
}

table {
  th,
  td {
    border: 1px solid;
  }
}

.order-editor-con {
  margin-top: 10px;
  label {
    font-weight: 400;
  }
  .order-editor {
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    background: white;

    > div > div {
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
      }

      &:hover {
        cursor: ns-resize;
      }

      .icon-controls {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.filter-editor-con {
  margin-top: 10px;

  label {
    font-weight: 400;
  }
  .filter-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
  }
  .filter-editor {
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    background: white;

    > div {
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
      }
    }
  }
}
</style>

<style scoped>
/* Skeleton Loader Animation */
@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Full-page skeleton container */
.page-skeleton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}

/* Container for the two boxes */
.skeleton-container {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  margin-right: 20px;
}

/* Common styles for both boxes */
.skeleton-box {
  height: 100%;
  border-radius: 0.428rem;
  animation: skeleton-loading 1.5s infinite ease-in-out;
  padding: 20px;
}

/* Large box (80% width) */
.skeleton-box.large {
  flex: 0 0 80%;
  height: 100%;
}

/* Small box (20% width) */
.skeleton-box.small {
  flex: 0 0 20%;
  height: 100%;
}
.skeleton-text {
  font-size: 24px;
}
</style>