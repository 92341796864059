<template>
  <div class="collapsable-container">
    <div class="collapsable-item" :class="{ active: !collapsed }">
      <div class="menu-head" @click="collapsed = !collapsed">
        <span><feather-icon :icon="icon" size="18" />{{ menuTitle }}</span>
        <feather-icon v-if="collapsed" size="22" icon="ChevronDownIcon" />
        <feather-icon v-else size="22" icon="ChevronUpIcon" />
      </div>
      <div class="menu-content" v-if="!collapsed">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: { FeatherIcon },
  props: {
    menuTitle:{
      required: true
    },
    icon:{
      required: false
    },
    defaultUncollapsed:{
      required:false,
      default: false
    }
  },
  data() {
    return {
      collapsed: !this.defaultUncollapsed,
    };
  },
};
</script>

<style lang="scss" scoped>
.collapsable-container {
  padding-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #e4e4e4;
  .collapsable-item {
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;

    &:hover,
    &.active {
      background-color: #f3f2f7;
      cursor: pointer;
    }

    .menu-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
    }

    .menu-content {
      padding-top: 10px;
    }
  }
}
</style>