<template>
  <fragment>
    <template v-for="item in data">
      <data-table-row
        :item="item"
        :key="`${item.rowId}-${level}`"
        :columns="columns"
        :level="level"
        :hidden="hidden"
        :selectedValuesOptions="selectedValuesOptions"
      />
    </template>
  </fragment>
</template>

<script>
import { BButton } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    FeatherIcon,
    BButton,
    "data-table-row": () => import("./DataTableRow.vue"), 
  },
  props: ["data", "columns", "hidden", "level", "selectedValuesOptions"],
  name: "data-table-row-level",
};
</script>