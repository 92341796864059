<template>
  <b-modal
    id="booststrapmodal"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    size="sm"
  >
    <h3>{{T('Web.DataInsightsPage.SelectDeselectOptions', 'Select/Deselect options')}}</h3>
    <b-form-checkbox
      v-for="option in options"
      :key="option.key"
      name="options"
      :value="option.key"
      v-model="content"
      :disabled="(disabledOptions && disabledOptions.includes(option.key))"
      >{{ option.label }}</b-form-checkbox
    >
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="light"
          class="float-right"
          @click="handleModalHidden"
        >
          {{ T("Web.Generic.Close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BButton,
    BFormCheckbox,
  },
  props: ["options", "value", "disabledOptions"],
  data() {
    return {
      visible: false,
      content: this.value,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    handleModalHidden() {
      this.visible = false;
    },
  },
  watch: {
    content() {
      this.$emit("input", this.content);
    },
  },
};
</script>